import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const WordmarkStyles = styled.p`
  font-weight: 700;
  font-family: 'Archivo', sans-serif, -apple-system, 'Helvetica Neue',
    BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', sans-serif;

  margin-bottom: 40px;

  font-size: 20px;
  line-height: 120%;

  letter-spacing: -0.2px;
  font-feature-settings: 'cpsp' on, 'ordn' on, 'salt' on;
`;

const GlobalNav = () => (
  <nav>
    <WordmarkStyles>Lodestone Studio</WordmarkStyles>
    {/* <ul>
      <li>
        <Link to="/">Page 1</Link>
      </li>
    </ul> */}
  </nav>
);

export default GlobalNav;
