/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { PreviewStoreProvider } from 'gatsby-source-prismic';

import GlobalLayout from './src/components/globalComponents/GlobalLayout';

export function wrapPageElement({ element, props, data }) {
  return <GlobalLayout {...props}>{element}</GlobalLayout>;
}

export function wrapRootElement({ element }) {
  return <PreviewStoreProvider>{element}</PreviewStoreProvider>;
}
