import React from 'react';

import '../../styles/globalStyles/reset.css';
import '../../styles/globalStyles/global.css';
import '../../styles/globalStyles/globalTheme.css';
import '../../styles/globalStyles/globalStyles.css';
import '../../styles/globalStyles/globalType.css';

import styled from 'styled-components';
import cssVars from 'css-vars-ponyfill';
import GlobalFooter from './GlobalFooter';
import GlobalNav from './GlobalNav';
import SEO from './SEO';

import SkipLink from './SkipLink';

const GlobalLayoutStyles = styled.div`
  margin: 24px auto 24px;
  padding: 24px;

  max-width: 1400px;
  width: auto;

  main {
    margin-bottom: 24px;
  }
`;

cssVars({
  include: 'style',
  onlyLegacy: false,
  watch: true,
  variables: {
    '--link-700': 'rgb(6, 129, 151)',
    '--grey-900': '#0e0506',
    '--blue-600': '#03389b',
    '--blue-100': '#f5f6f8',
    '--red-600': '#9b000a',
    '--red-100': '#fcebec',
    '--grey-500': '#425a80',
  },
});

const Layout = props => {
  if (!props) return null;
  const { title, image, location, children } = props;

  return (
    <GlobalLayoutStyles>
      <SEO image={image} title={title} location={location.pathname} />
      <GlobalNav />
      {/* <SkipLink /> */}
      <>{children}</>
      <GlobalFooter />
    </GlobalLayoutStyles>
  );
};

export default Layout;
