import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import emailIcon from '../../assets/svg/email.svg';
import twitterIcon from '../../assets/svg/twitter.svg';
import instagramIcon from '../../assets/svg/instagram.svg';

const FooterStyles = styled.footer`
  margin-top: auto;
  padding-top: 48px;

  color: var(--grey-500);

  a:any-link {
    color: var(--grey-500);
    text-decoration: none;
  }

  li,
  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
  }

  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  ul {
    gap: 3rem;
  }

  li {
    font-family: 'Archivo', sans-serif, 'Helvetica Neue', -apple-system,
      BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, sans-serif;
    font-size: 16px;
    line-height: 32px;
  }

  li {
    min-width: 106px;
    max-width: 320px;
  }

  li,
  a {
    display: flex;
    flex-direction: row;
  }

  li {
    align-items: center;
  }

  img {
    margin-right: 8px;
  }

  li {
    margin-bottom: 1rem;
    padding: 8px;
  }

  li:first-child {
    margin-right: auto;
  }

  @media screen and (max-width: 720px) {
    padding-bottom: 4rem;
    ul {
      gap: 1rem;
    }

    li {
      max-width: auto;
    }
  }
`;

function GlobalFooter(props) {
  const data = useStaticQuery(graphql`
    query footerComponentQuery {
      prismicFooterComponent {
        data {
          copyright_text
          social_links {
            social_media_service
            social_media_link {
              link_type
              url
            }
          }
        }
      }
    }
  `);

  const footerDataRes = data.prismicFooterComponent.data;

  // CONTENT
  const copyrightText = footerDataRes.copyright_text;
  const socialLinks = footerDataRes.social_links;

  return (
    <FooterStyles>
      <ul>
        <li>{copyrightText}</li>
        <li>
          <a href={socialLinks[0].social_media_link.url}>
            <img
              width="32"
              height="32"
              src={instagramIcon}
              alt="Instagram Logo Icon"
            />
            {socialLinks[0].social_media_service}
          </a>
        </li>
        <li>
          <a href={socialLinks[1].social_media_link.url}>
            <img
              width="32"
              height="32"
              src={twitterIcon}
              alt="Twitter Logo Icon"
            />
            {socialLinks[1].social_media_service}
          </a>
        </li>
        <li>
          <a href={socialLinks[2].social_media_link.url}>
            <img
              width="32"
              height="32"
              src={emailIcon}
              alt="Twitter Logo Icon"
            />
            {socialLinks[2].social_media_service}
          </a>
        </li>
      </ul>
    </FooterStyles>
  );
}

export default GlobalFooter;
